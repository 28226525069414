#experience {
  position: relative;
}
#line {
  position: absolute;
  width: 20px;
  background: var(--bg3) !important;
  height: 90%;
  left: 50%;
  transform: translateX(-50%);
}
#arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid var(--fg4);
  border-right: 20px solid var(--fg4);
  border-bottom: 20px solid var(--bg3);
  left: 50%;
  transform: translateX(-50%);

}
