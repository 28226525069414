.fecha {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.fecha h4 {
  background-color: var(--fg);
  padding: .4rem;
  border-radius: 10px;
  text-align: left;
}

.tech-icon-experience-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.img-container-experience {
  display: flex;
  align-items: center;
}
