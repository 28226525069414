.header-align{
  width: 100%;
}
.github-icon {
  font-size: 2rem;
  color: var(--fg);
  text-decoration: none;
}
.align-text {
  display: flex-end;
  align-items: center;
}

.card-repo {
  width: 100%;
}
