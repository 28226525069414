.proyects-backgroung {
  padding: 5em;
  background-color: var(--bg_s);
}
.color-aqua {
  color: var(--aqua);
}
.tech-icon-project {
  font-size: 4.5rem;
  color: var(--fg);
  text-decoration: none;
  padding: 10px 10px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.proyect-card {
  height: 40rem;
}

.card-content {
  height: 100%;
}

/* Extra small devices (phones, <576px) */
@media (max-width: 575.98px) {
  .proyect-card {
    height: 40rem;
  }
  .proyects-backgroung {
    padding: 1em;
  }
}

/* Small devices (phones, ≥576px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .proyect-card {
    height: 40rem;
  }
  .proyects-backgroung {
    padding: 1em;
  }
}

/* Medium devices (tablets, ≥768px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .proyect-card {
    height: 25rem;
  }
}

/* Large devices (desktops, ≥992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .proyect-card {
    height: 23rem;
  }
}

/* Extra large devices (large desktops, ≥1200px) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .proyect-card {
    height: 23rem;
  }
}

/* Extra extra large devices (extra large desktops, ≥1400px) */
@media (min-width: 1400px) {
  .proyect-card {
    height: 23rem;
  }
}
