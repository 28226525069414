:root {
  --bg_h: #1d2021;
  --bg:   #282828;
  --bg_s: #32302f;
  --bg1:  #3c3836;
  --bg2:  #504945;
  --bg3:  #665c54;
  --bg4:  #7c6f64;

  --fg:  #fbf1c7;
  --fg1: #ebdbb2;
  --fg2: #d5c4a1;
  --fg3: #bdae93;
  --fg4: #a89984;

  --red:    #fb4934;
  --green:  #b8bb26;
  --yellow: #fabd2f;
  --blue:   #83a598;
  --purple: #d3869b;
  --aqua:   #8ec07c;
  --gray:   #928374;
  --orange: #fe8019;

  --red-dim:    #cc2412;
  --green-dim:  #98971a;
  --yellow-dim: #d79921;
  --blue-dim:   #458588;
  --purple-dim: #b16286;
  --aqua-dim:   #689d6a;
  --gray-dim:   #a89984;
  --orange-dim: #d65d0e;
}

/* Normal Colors */

.red {
  color: var(--red) !important;
}

.green {
  color: var(--green);
}

.yellow {
  color: var(--yellow);
}

.blue {
  color: var(--blue);
}

.purple {
  color: var(--purple);
}

.aqua {
  color: var(--aqua);
}

.gray {
  color: var(--gray);
}

.orange {
  color: var(--orange) !important;
}

/* Dim Colors */

.red-dim {
  color: var(--red-dim);
}

.green-dim {
  color: var(--green-dim);
}

.yellow-dim {
  color: var(--yellow-dim);
}

.blue-dim {
  color: var(--blue-dim);
}

.purple-dim {
  color: var(--purple-dim);
}

.aqua-dim {
  color: var(--aqua-dim);
}

.gray-dim {
  color: var(--gray-dim);
}

.orange-dim {
  color: var(--orange-dim);
}

/* Foreground Colors */

.fg-text {
  color: var(--fg) !important;
}

.fg1-text {
  color: var(--fg1) !important;
}

.fg2-text {
  color: var(--fg2) !important;
}

.fg3-text {
  color: var(--fg3) !important;
}

.fg4-text {
  color: var(--fg4) !important;
}
/* Foreground Colors */

.fg {
  background-color: var(--fg) !important;
}

.fg1 {
  background-color: var(--fg1) !important;
}

.fg2 {
  background-color: var(--fg2) !important;
}

.fg3 {
  background-color: var(--fg3) !important;
}

.fg4 {
  background-color: var(--fg4) !important;
}

/* Background Colors */

.bg-hard {
  color: var(--bg_h) !important;
}

.bg {
  color: var(--bg);
}

.bg-soft {
  color: var(--bg_s);
}

.bg1 {
  color: var(--bg1);
}

.bg2 {
  color: var(--bg2);
}

.bg3 {
  color: var(--bg3);
}

.bg4 {
  color: var(--bg4);
}
