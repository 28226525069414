body {
  margin: 0;
  padding: 0;
  background-color: var(--fg4);
  font-family: 'Fira Code', monospace;
}

h1{
  color: var(--bg)
}

strong{
  color: var(--bg)
}

