.background {
  background-color: var(--bg);
}

@media screen and (max-width: 800px) {
  .col-user-card {
    display: flex;
    justify-content: center;
  }
}

.gruv-nav-link:active {
  color: var(--green) !important;
}

.gruv-nav-link:hover {
  color: var(--green) !important;
}

.gruv-nav-link {
  display: block;
  color: var(--fg) !important;
  text-decoration: none;
  padding: 10px 10px;
}


.active {
  color: var(--green) !important;
}

.colors {
  color: var(--fg);
}

.gruv-nav-brand {
  color: var(--fg);
  text-decoration: none;
  padding: 10px 10px;
  font-size: 1.5rem;
}

.gruv-nav-brand:hover {
  color: var(--green);
}

.icons-container {
  display: flex;
}

.icons-container .gruv-nav-link {
  font-size: 2rem;
}
